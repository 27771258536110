
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import CustomTooltip, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { DayRateInsightData } from '../../types';
import InsightDayRateModel from '../../models/insight-day-rate.model';
import InsightsService, { InsightsServiceS } from '../../insights.service';

@Component({
    components: {
        ModalWrapper,
        CustomTooltip,
    },
})
export default class DayRatesTable extends Vue {
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(InsightsServiceS) private insightsService!: InsightsService;

    hint: HTMLElement | null = null;
    tooltipDirection = TOOLTIP_DIRECTION.RIGHT;

    handleHover(e: MouseEvent) {
        this.hint = e.type === 'mouseover'
            ? e.currentTarget as HTMLElement
            : null;
    }

    get hintData() {
        if (!this.dayData) {
            return {};
        }

        if (!this.hint) {
            return [[], [], []];
        }

        const index = parseInt(this.hint.dataset.index as string, 10);
        const data = this.dayData[index];

        return Object.entries<string | number | null>(data.filters);
    }

    get rawData() {
        return this.insightsService.dayData as InsightDayRateModel | null;
    }

    get dayData() {
        if (!this.rawData?.data) {
            return [];
        }

        return Object.entries<DayRateInsightData>(this.rawData.data).map(([id, hotel]) => {
            const hotelData = {
                id,
                name: hotel.name,
                price: hotel.price,
                currency: hotel.currency,
                filters: {
                    'Room Name': hotel.filters.roomName || '',
                    'Meal Type': this.mealTypesService.getMealType(hotel.filters.mealTypeId)?.displayName || '',
                    'Price Type': hotel.filters.priceType || '',
                },
            } as {
                id: string;
                name: string;
                price: number | null;
                currency: string | null;
                filters: Record<string, string>;
            };

            if (hotel.filters.newPriceType) {
                hotelData.filters['New Type'] = hotel.filters.newPriceType as string;
            }

            if (hotel.filters.missingPriceTypes) {
                hotelData.filters['Missing Type'] = hotel.filters.missingPriceTypes as string;
            }

            return hotelData;
        });
    }

    isMyHotel(id: string | number) {
        return this.$route.params.hotelId === String(id);
    }

    formatPrice(price: number | null, currency: string | null) {
        if (price === null || !currency) {
            return 'No Data';
        }

        switch (price) {
            case PRICE.SOLD_OUT:
                return 'Sold Out';
            case PRICE.NA:
                return 'N/A';
            default:
                return this.currencySymbol(currency) + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }
}
